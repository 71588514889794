/**
 * 404 page styles
 */
@import '../../partials/_mixins';
@import '../../partials/_variables';

@color-title: #010101;

.font-face('Lato', '@{fonts-path}lato/black/lato-black', 900, normal, 'latoblack');
.font-face('Lato', '@{fonts-path}lato/bold/lato-bold', 700, normal, 'latobold');
.font-face('Lato', '@{fonts-path}lato/light/lato-light', 300, normal, 'latolight');
.font-face('Lato', '@{fonts-path}lato/regular/lato-regular', 400, normal, 'latoregular');

body,
html {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
}

.errors {
  a {
    color: #433d3d;
    text-decoration: none;
  }

  &__content {
    box-sizing: border-box;
    margin: auto;
    max-width: 991px;
    padding: 0 15px;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
  }

  &__header {
    h1 {
      display: flex;
      font-size: 16px;
      justify-content: center;
      text-align: center;

      a img {
        box-sizing: border-box;
        height: 3.75em;
        max-width: 400px;
        padding: 0.5em;
        width: 100%;
      }
    }
  }

  &__block {
    border: 1px solid @color-title;
    border-radius: 5px;
    box-sizing: border-box;
    color: @color-text-title;
    flex-basis: 100%;
    font-size: 0.875rem;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;

    p {
      color: @color-text-primary;
      line-height: 1.6em;
      margin: 0 10px 8px;
    }

    a {
      color: @color-secondary-500;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @media @tablet {
      flex-basis: calc(100% / 2 - 12px);
      margin: 6px;
    }

    @media @desktop {
      flex-basis: calc(100% / 3 - 12px);
      margin: 6px;
    }
  }

  &__highlight {
    float: left;
    height: 40px;
    line-height: 40px;
    margin: 0 0 15px 15px;
    text-transform: uppercase;
    width: calc(100% - 50px);

    a {
      color: @color-title;
      display: inline-block;
      font-size: 1rem;
      line-height: 1.2em;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__flags {
    background: url('/errors/assets/images/flags.jpg') no-repeat;
    float: left;
    height: 40px;
    margin-bottom: 15px;
    position: relative;
    text-indent: -9999px;
    width: 35px;

    &--fr {
      background-position: -66px 7px;
    }

    &--en {
      background-position: -100px 7px;
    }

    &--de {
      background-position: 5px 7px;
    }

    &--es {
      background-position: -32px 7px;
    }

    &--it {
      background-position: -137px 7px;
    }

    &--pt {
      background-position: -267px 7px;
    }

    &--nl {
      background-position: -202px 7px;
    }

    &--zh {
      background-position: -300px 7px;
    }

    &--pl {
      background-position: -235px 7px;
    }

    &--ja {
      background-position: -170px 7px;
    }

    &--pt-br {
      background-position: -335px 7px;
    }

    &--ru {
      background-position: -368px 7px;
    }

    &--id {
      background-position: -405px 7px;
    }

    &--ko {
      background-position: -444px 7px;
    }

    &--tr {
      background-position: -480px 7px;
    }

    &--th {
      background-position: -555px 7px;
    }

    &--ar {
      background-position: -519px 7px;
      overflow: hidden;
    }

    &--sv {
      background-position: -593px 7px;
    }
  }

  &__link {
    text-align: right;

    a {
      color: @color-secondary-500;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.clear {
  clear: both;
}
