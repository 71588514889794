/**
 * Mixins partial styles
 */
@import './_variables';

.background-cover(@url, @position: center) {
  background-image: url('@{url}');
  background-position: @position;
  background-size: cover;
}

.clear-fix {
  clear: both;
  content: '';
  display: block;
}

.center-absolute {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

// @see https://css-tricks.com/snippets/css/using-font-face
.font-face(@font-name, @fonts-path, @font-weight, @font-style, @svg-id) {
  @font-face {
    font-family: @font-name;
    font-display: swap;
    font-style: @font-style;
    font-weight: @font-weight;
    src: url('@{fonts-path}.eot');
    src: url('@{fonts-path}.eot?#iefix') format('embedded-opentype')
      , url('@{fonts-path}.woff2') format('woff2')
      , url('@{fonts-path}.woff') format('woff')
      , url('@{fonts-path}.ttf') format('truetype')
      , url('@{fonts-path}.svg#@{svg-id}') format('svg');
  }
}

/**
 * px to em
 *
 * @property: font-size, width, height ...
 * @target : your value in px to convert (you can use the unit or not)
 * @context : by default your base font size or the parent font size
 *
 * Examples :
 * foo-1 {
 *   .em(font-size, 18);
 *   // font-size: 1.125em
 * }
 * foo-2 {
 *   .em(font-size, 18px);
 *   // font-size: 1.125em
 * }
 * .foo-3 {
 *   .em(font-size, 14, 18);
 *   // font-size: 0.77777778em
 * }
 */
.em(@property; @target; @context: @base-font-size) {
  @emValue: (unit(@target) / unit(@base-font-size));
  @{property}: ~'@{emValue}em';
}

/**
 * px to rem
 *
 * @property: font-size, width, height ...
 * @target : your value in px to convert (you can use the unit or not)
 *
 * Examples :
 * .foo-1 {
 *   .rem(font-size, 18);
 *   // font-size: 1.125rem
 * }
 * .foo-2 {
 *   .rem(font-size, 18px);
 *   // font-size: 1.125rem
 * }
 */
.rem(@property; @target) {
  @remValue: (unit(@target) / unit(@base-font-size));
  @{property}: ~'@{remValue}rem';
}
